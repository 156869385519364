
import { defineComponent, getCurrentInstance } from 'vue';
import Button from '@/components/Button.vue'
export default defineComponent({
name : 'DateRange',
components:{
  Button
},
data(){
  return{
    _uid:getCurrentInstance()?.uid,
    isResizing: false,
    newHeight:0
  }
},
props:{
  height: String,
  marginTop: String,
  text: String,
  disabled:{
    type: Boolean,
    default: false
  },
  limit: {
    type: Number,
    default: String
  }
},
methods:{
  start(event: any){
    const handler: any = document.getElementById('DateRange-'+this._uid);
    if (this.isResizing) {
        this.newHeight = event.clientY - handler.getBoundingClientRect().top;
        if(this.newHeight >= this.limit) this.newHeight = this.limit
        handler.style.height = this.newHeight + 'px';
    }
  },
  stop(event: any){
    this.isResizing = false
    this.$emit('update', this.newHeight)
  },
  remove(e: Event){
    e.stopPropagation()
    this.$emit('delete')
  }
},
mounted(){
  if(this.disabled) return
  const handler: any = document.getElementById('DateRange-'+this._uid);
  const vm = this
  handler.addEventListener('mousedown', function (e: any) {
        vm.isResizing = true;
        document.addEventListener('mousemove', vm.start);
        document.addEventListener('mouseup', vm.stop);
    });
}
})
