
import { defineComponent, getCurrentInstance } from 'vue';
import Button from '@/components/Button.vue'
import moment from 'moment-timezone';
export default defineComponent({
name : 'DateRange',
components:{
  Button
},
data(){
  return{
    _uid:getCurrentInstance()?.uid,
    isResizing: false,
    newHeight:0
  }
},
props:{
  height: String,
  marginTop: String,
  testersWanted: Number,
  confirmedNum: Number,
  slot:Object
},
computed:{
  text(){
    return `${this.confirmedNum} / ${this.testersWanted} personas`
  },
  date(){
    return moment(this.slot?.dateFrom).format('DD.MM | HH:mm') + ' - ' + moment(this.slot?.dateTo).format("HH:mm")
  }
},
methods:{

},
mounted(){

}
})
